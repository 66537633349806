import { env } from 'src/env.mjs';

const apiPath = env.NEXT_PUBLIC_API_PATH; // Get the base path from the environment variables.

export default {
    RENEWAL_QUIZZES_START_QUIZ: `${apiPath}/api/v2/renewal_quizzes/start_quiz`,
    RENEWAL_QUIZZES_SUBMIT_ANSWER: `${apiPath}/api/v2/renewal_quizzes/submit_answer`,
    INITIAL_QUIZZES_START_QUIZ: `${apiPath}/api/v2/initial_quizzes/start_quiz`,
    INITIAL_QUIZZES_SUBMIT_ANSWER: `${apiPath}/api/v2/initial_quizzes/submit_answer`,
    HEALTH_TRACKING_QUIZZES_START_QUIZ: `${apiPath}/api/v2/health_tracking_quizzes/start_quiz`,
    HEALTH_TRACKING_QUIZZES_SUBMIT_ANSWER: `${apiPath}/api/v2/health_tracking_quizzes/submit_answer`,
    USER: (uuid: string) => `${apiPath}/api/v2/users/${uuid}`,
    APPLE_LOGIN: `${apiPath}/api/v2/tokens/request/apple`,
    GOOGLE_LOGIN: `${apiPath}/api/v2/tokens/request/google`,
    PAYMENT: (
        paymentType:
            | 'card_payment'
            | 'initialize_paypal'
            | 'initialize_afterpay',
    ) => `${apiPath}/api/v2/payments/${paymentType}`,
    CONSULT_CALCULATE_WAIT_TIME: (quizToken: string, timezone: string) =>
        `${apiPath}/api/v2/callbacks/calculate_wait_time/${quizToken}?timezone=${timezone}`,
    CONSULT_ADD_TO_QUEUE: `${apiPath}/api/v2/callbacks/add_to_queue`,
    ADDRESS_HDS_VALIDATE: (
        suburb: string,
        postcode: string,
        quizToken: string,
    ) => {
        const params = new URLSearchParams({ suburb, postcode, quizToken });

        return `${apiPath}/api/v2/addresses/hds_validate?${params.toString()}`;
    },
    APPLE_CALLBACK: `${apiPath}/api/v2/tokens/apple/callback`,
    MEDICAL_SUPPORT_TICKET_LIST: (uuid: string, pageType, page) =>
        `${apiPath}/api/v2/users/${uuid}/medical_support/ticket_list?type=${pageType}&page=${page}`,
    MEDICAL_SUPPORT_CREATE_TICKET: (uuid: string) =>
        `${apiPath}/api/v2/users/${uuid}/medical_support`,
    MEDICAL_SUPPORT_TICKET: (uuid: string, token: string) =>
        `${apiPath}/api/v2/users/${uuid}/medical_support/${token}`,
    MEDICAL_SUPPORT_TICKET_MESSAGE: (uuid: string) =>
        `${apiPath}/api/v2/users/${uuid}/medical_support/create_message`,
    MEDICATIONS: (uuid: string) =>
        `${apiPath}/api/v2/users/${uuid}/medications`,
    MEDICATION: (uuid: string, medicationId: string) =>
        `${apiPath}/api/v2/users/${uuid}/medications/${medicationId}`,
    MEDICATION_ARTICLES: (uuid: string, medicationId: string) =>
        `${apiPath}/api/v2/users/${uuid}/medications/${medicationId}/articles`,
    MEDICATION_STEP: (uuid: string, medicationId: string, stepId: string) =>
        `${apiPath}/api/v2/users/${uuid}/medications/${medicationId}/step/${stepId}`,
    LOGIN: `${apiPath}/api/v2/tokens/request`,
    VERIFY_EMAIL: `${apiPath}/api/v2/users/verify_email`,
    VERIFY_PHONE: `${apiPath}/api/v2/users/verify_phone`,
    CREATE_ACCOUNT: `${apiPath}/api/v2/users`,
    POSTCODE_SEARCH: (query: string) => {
        const params = new URLSearchParams({ query });

        return `${apiPath}/api/v2/addresses/postcode_search?${params.toString()}`;
    },
};

'use client';

import React, { useState } from 'react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { SharedProvider, ToastProvider } from '@get-mosh/msh-shared';
import { AppProvider } from 'src/context/app';
import DatadogInit from 'src/analytics/Datadog';
import { env } from 'src/env.mjs';
import { GoogleOAuthProvider } from '@react-oauth/google';
import AnalyticsProvider from 'src/analytics/Analytics';

type ProvidersProps = {
    children: React.ReactNode;
};

function Providers({ children }: ProvidersProps) {
    const [client] = useState(new QueryClient());

    return (
        <AppProvider>
            <QueryClientProvider client={client}>
                <AnalyticsProvider>
                    <GoogleOAuthProvider
                        clientId={env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
                    >
                        <SharedProvider theme={env.NEXT_PUBLIC_BRAND}>
                            <ToastProvider>
                                <DatadogInit />
                                {children}
                            </ToastProvider>
                        </SharedProvider>
                    </GoogleOAuthProvider>
                </AnalyticsProvider>
            </QueryClientProvider>
        </AppProvider>
    );
}

export default Providers;

import { isMosh } from 'src/env.mjs';
const brands = {
    MOSH: 'Mosh',
    MOSHY: 'Moshy',
};

export type BrandType = (typeof brands)[keyof typeof brands];
export default brands;

export const displayBrand: string = isMosh ? brands.MOSH : brands.MOSHY;
